@import "mixins"
.prematchTournamentContainer
  min-height: var(--withoutHeaderHeight)

.tournamentGameHeader
  background: var(--sport_tournamentGameHeaderBg)
  margin-top: 1px
  height: 36px
  z-index: 1
  position: relative
  @include flex
  overflow: hidden
  padding: 0 14px

.arrow
  @include backArrowBtn

.tournamentGameHeaderCategoryName
  text-shadow: 0 -1px rgba(0, 0, 0, 0.4)
  margin-left: 14px
  @include bold
  color: #fff
  > span
    margin-left: 4px
    color: rgb(var(--primaryColor))

.categoryName
  @include flex
  height: 50px
  padding: 0 10px
  color: #fff
  > span:first-of-type
    color: rgb(var(--primaryColor))
    > span
      margin-left: 4px
      color: var(--live_favoritesTournamentItemOpenedColor)

.gameCount
  margin-left: auto

.gamesContainer
  overflow: hidden
  padding-bottom: 20px
