@import "mixins"
.block
  margin-bottom: 6px
  background: var(--acount_detailsSectionBg)
  border-radius: 3px
  overflow: hidden
  position: relative

.header
  @include flex
  justify-content: space-between
  height: 44px
  border-radius: 3px 3px 0 3px
  padding: 0 12px
  text-transform: uppercase
  background: var(--acount_detailsSectionHeaderBg)
  box-shadow: inset 1px 0 0 rgb(var(--particularColor))
  color: #fff
  position: relative
  z-index: 1

.editBtn
  width: 40px
  height: 32px
  @include flex
  justify-content: center
  background: var(--acount_detailsSectionEditBtnBg)
  border-radius: 3px
  span
    @include flex
    svg path
      fill: rgb(var(--primaryColor))
  &:active
    background: var(--body_bg)

.viewInfo
  padding: 0 12px
  position: relative

.userInfoLoader > span
  font-size: 14px

.viewDetails > div
  @include flex
  height: 50px

  &:not(:first-of-type)
    border-top: 1px dotted var(--acount_detailsSectionBorder)

  :global(.svg-icon)
    margin: 0 18px 0 4px
    path
      fill: var(--account_iconBg)

.mobile,
.email
  .verified,
  button
    margin-left: auto

  button
    height: 30px
    min-width: 64px
    background: var(--acount_verifyBtnBg)
    color: #fff
    &:active
      background: var(--acount_verifyBtnActive)

.email
  border-bottom: 1px dotted var(--acount_detailsSectionBorder)
  :global(.svg-icon) svg
    width: 14px
    height: 12px
    margin-left: -1px
    margin-right: 1px
  + p
    color: var(--account_detailsTxtColor)
    padding: 12px 0 10px
    line-height: 16px

.language
  padding: 0 2px
  @include flex
  height: 48px

  :global(.svg-icon)
    margin-right: 18px
    path
      fill: var(--account_iconBg)

.verifyModal
  z-index: 1003
  --headerHeight: 0
  --menuHeight: 0
  :global(.modal)
    height: auto
    padding: 28px 28px 8px
    box-shadow: 0 0 4px 4px rgb(0 0 0 / 20%)
    border-radius: 3px
    > div
      border: 0
      padding: 0
      margin: 0

      .text
        color: rgb(var(--primaryColor))
        line-height: 16px

        strong
          margin: 0 4px

      > section
        justify-content: center
        min-height: 120px
        margin: 10px 0

@media screen and (max-width: 320px) and (orientation: portrait)
  .verifyModal :global(.modal)
    padding: 12px 10px 2px
  .viewInfo
    padding: 0 6px
